import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { useState,useEffect,useContext,useMemo } from 'react';
import  styles  from  './Replies.module.css';
import { Card,Form,Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {UserContext} from "../App";
import Commentlike from './Commentlike';
function Replies(props) {
    const [offset, setOffset] = useState(0);
    const [scroll,setScroll] = useState(false);
    const[contents,setContent]  = useState([]);
    const[inputs,setInputs] = useState([]);
    const[reply,setReply] = useState('');
    const  navigate = useNavigate();
    const [isReplies,setReplies] = useState(false);
    const [isFocustxtcomment,setFocustxtcomment] = useState(false);
    const user = useContext(UserContext);
    const[choicecontents,setchoicecontent]  = useState([]);
  
    const getRepliesmemo = useMemo(()=>{
      return getReplies(props.commentID);
    },[])
    useEffect(() => {
  
      setReply('');
        insertUserID();
        insertcommentID();
        getChoiceContent(props.userID);
        getReplies(props.commentID)
     
    }, [props.reload]);
    const handlechange =  (event)=>{
      
        const name = event.target.name;
        const value = event.target.value;
        event.target.style.height = `24px`;
        event.target.style.height = `${event.target.scrollHeight}px`;

        setReply(event.target.value);
        setInputs(values=>({...values,[name]:value}));
        
      }
      
      const cancel = async(event)=>{
        setInputs([]);
        setReply('');
        getReplies(props.commentID);
        insertUserID();
        insertcommentID();
        onBlurtxtcomment();
       
     
      }
      function insertUserID(){
        const name = "userID";
        const value = props.userID;
  
        setInputs(values=>({...values,[name]:value}))
      }
      function insertcommentID(){
        const name = "commentID";
        const value = props.commentID;
    
        setInputs(values=>({...values,[name]:value}))
      }
      function getReplies(commentID){
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`http://localhost/API/replies.php/${commentID}/replies`,axiosConfig).then(function(response){
          setContent(response.data);
        
         
          
        });
        
       
      }
      function getChoiceContent(userID){
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        
        axios.get(`http://localhost/API/choice.php/${userID}`,axiosConfig).then(function(response){
          setchoicecontent(response.data);
      

        });
        
      }
     
      function onFocustxtcomment(){
        setFocustxtcomment(true);
    }
    function onBlurtxtcomment(){
      setFocustxtcomment(false);
  }
  return (
 
    
            <Row className={styles.row}> 
                        
                        <Col md="12">
                      
                        <Container fluid="md" >
                        {contents.map((content,key)=>
                        {

                          return(    
                            <div key={key} >
                                 <Row  className={styles.rowComment+" "+'justify-content-center'}>
                                  <Col md="1" lg="2" className = {styles.colLogo}> 
                                    <Image rounded  src={userImage} className={styles.imgLogo}/>
                                    
                                    </Col>
                                    <Col md="5" lg="9" className = {styles.Colname}> 
                                    <Stack gap={0} >
                                    <Card.Text className={styles.cardText}>{content==[] ? null : content.firstname + " " +content.lastname}</Card.Text>
                                    <Card.Text className={styles.cardTextTime}>{content==[] ? null :content.timeago+" ago"}</Card.Text>
                                
                          
                                    </Stack>
                                    
                                    </Col>
                                </Row>
                              <Row className={styles.rowComment+" "+'justify-content-center'}>
                              <Col md="2"> 
                                  
                                    
                              </Col>
                              <Col md="9" className={styles.colContent}> 
                              <label className={styles.cardCotent}>
                              {content==[] ? null :content.reply}
                                  
                                  </label>
                                  
                                  </Col>
                              </Row>
                              <Row>
                                <Col md="12" className={styles.colLikebtn}>
                                <Stack gap={0} direction="horizontal">
                                  <Commentlike userID = {props.userID} commentID = {content.commentID} type = {"reply"}/>
                            </Stack>
                              
                                </Col>
                                </Row>
                            </div>
                   
                      
                      );
                      }
                          
                      )
                      }
                       {choicecontents.map((choicecontent,key)=>
                        {
                          return(
                        <Form  key={key} name="a" onSubmit={event=>props.addreply(event,props.commentID,inputs)} style={{display:props.postID==choicecontent.postID?"":"none"}}> 
                        <Row >
                            <Col lg = "12" className={styles.colComentTextarea}>
                             
                                <Stack gap={3}  direction ={"horizontal"}>
                                <Image rounded  src={userImage} className={styles.imgLogo}/>
                                <Form.Control as="textarea"  value={reply} className={styles.comentTextarea} name = "reply" onChange={handlechange}  placeholder="Leave a reply here" onFocus={onFocustxtcomment} />  
                                <Button type="submit"  className={styles.btnComment} >
                                        Comment
                                  </Button>
                                  </Stack>
                            </Col>
                        
                        </Row>
                        <Row>
                
                    </Row>
                 
                        </Form>
                              );
                            }
                                
                            )
                            }
                        </Container>
                       
                        </Col>
            </Row>
          
      
    
  );
}

export default Replies;

