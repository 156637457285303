

import { useEffect, useState, useContext } from 'react';


import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import styles from './SaveButton.module.css';
import { FaRegBookmark } from "react-icons/fa";

import { FaBookmark } from "react-icons/fa";
function SaveButton(props) {

    const [prosisActivve, setproisActive] = useState(true);
    const [offset, setOffset] = useState(0);
    const [scroll, setScroll] = useState(false);
    const [contents, setContent] = useState([]);
    const [choicecontents, setchoicecontent] = useState([]);
    const [countcomments, setcountcomments] = useState([0]);
    const [countpros, setcountpros] = useState([0]);
    const [countcons, setcountcons] = useState([0]);
  
    const [comment, setComment] = useState('');
    const [choice, setchoice] = useState('');
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [isFocustxtcomment, setFocustxtcomment] = useState(false);
    const [isSave,setSave] = useState(false);
    const inputs = {inputs: {
      postID:0,
      save: "",
      userID:0
      }}



    function saved(postID){
            
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`http://localhost/API/savepost.php/${postID}`,axiosConfig).then(function(response){

          //count = response.data['count'];
          
          if(response.data>0){ 
            setSave(true);
            console.log(isSave);
          }
          else{
            setSave(false);
          }
       
        });
       
        return isSave
        }
       function savePost(event,postID,save){
        event.preventDefault();
        setSave(!isSave);
        //dispatch(addLike({commentID:commentID,like:like,userID:props.userID}));
        inputs.postID = postID;
        inputs.save = save;
        inputs.userID = props.userID;
        console.log(props.userID);
     
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
       axios.post(`http://localhost/API/savepost.php`, inputs,axiosConfig).then(function(response){
         
           
 
        
        });
        
        }


    return (




        <>
         {saved(props.postID)?<FaBookmark className={styles.iconSaved} onClick={event=>savePost(event,props.postID,false)}/>:<FaRegBookmark className={styles.icon} onClick={event=>savePost(event,props.postID,true)}/>}
        </>
        
              
               
          

    );
}

export default SaveButton;